<template>
  <div>
    <el-dialog title="Change Day" :visible.sync="dialogDayNames">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Check all</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedDayNames" @change="handleDayNameChange">
        <el-checkbox v-for="day in daynames" :label="day.value" :key="day.value" :value="day.value">{{day.name}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDayNames = false">Cancel</el-button>
        <el-button type="primary" @click="updateDayName">Confirm</el-button>
      </span>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0">WORK SCHEDULE ON LOCATION</h3>
        <el-select v-model="bbl_id" placeholder="Select Location" filterable>
          <el-option v-for="location in locations" :key="location.id" :value="location.id" :label="location.name"></el-option>
        </el-select>
      </div>
      <el-table border :data="data">
        <el-table-column label="NAME" prop="name"></el-table-column>
        <el-table-column label="DAY/s" prop="arr_allow_dayname">
          <template slot-scope="props">
            <div v-if="props.row.allow_dayname">
              <p style="margin: 0" v-for="id in props.row.allow_dayname.split(',')" :key="id">{{daynames.find(day => day.value === id).name}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="TIME FROM" prop="time_from"></el-table-column>
        <el-table-column label="TIME TO" prop="time_to"></el-table-column>
        <el-table-column label="STATUS" prop="is_visible">
          <template slot-scope="props">
            <el-switch v-model="props.row.is_visible"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Change Day/s" placement="top-start">
              <el-button el-button size="mini" type="primary" @click="changeDayNames(scope.row.id)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="controls">
        <el-button type="primary" @click="confirmUpdate">Save Changes</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedShifts: [],
      locations: [],
      initialState: null,
      data: [],
      daynames: [
        {
          value: '1',
          name: 'Monday'
        },
        {
          value: '2',
          name: 'Tuesday'
        },
        {
          value: '3',
          name: 'Wednesday'
        },
        {
          value: '4',
          name: 'Thursday'
        },
        {
          value: '5',
          name: 'Friday'
        },
        {
          value: '6',
          name: 'Saturday'
        },
        {
          value: '7',
          name: 'Sunday'
        }
      ],
      checkedDayNames: [],
      bbl_id: null,
      checkAll: false,
      isIndeterminate: true,
      dialogDayNames: false,
      dayname: {
        id: null,
        allow_dayname: null
      },
      form: {
        em_id: this.$store.getters.user.employeeId,
        ids_allow: [],
        ids_notallow: []
      }
    }
  },
  watch: {
    bbl_id () {
      this.getShiftSchedules()
    }
  },
  mounted () {
    this.getLocationInfo()
  },
  methods: {
    getShiftSchedules () {
      this.$http
        .get(`timekeeper.mss/shift-list/${this.$store.getters.user.employeeId}/${this.bbl_id}/1/0`)
        .then(res => {
          this.data = res.data.body.map(row => {
            row.is_visible = Boolean(parseInt(row.is_visible))
            return row
          })
          this.initialState = this.data
        })
    },
    getLocationInfo () {
      this.$http
        .get(`locationinfo.locations/manager-list/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.locations = res.data.body
        })
    },
    handleCheckAllChange (val) {
      this.checkedDayNames = val ? this.daynames.map(day => day.value) : []
      this.isIndeterminate = false
    },
    handleDayNameChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.daynames.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.daynames.length
    },
    confirmUpdate () {
      this.$confirm('Are you sure you want to enable/disable the statuses of these records?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'info'
      })
        .then(() => {
          this.update()
        })
    },
    update () {
      this.form.ids_allow = this.initialState.filter(initial => {
        if (!initial.is_visible) {
          return false
        }
        const hasChanged = this.data.find(changed => changed.id === initial.id)
        return initial.is_visible === hasChanged.is_visible
      }).map(shift => shift.id)
      this.form.ids_notallow = this.initialState.filter(initial => {
        if (initial.is_visible) {
          return false
        }
        const hasChanged = this.data.find(changed => changed.id === initial.id)
        return initial.is_visible === hasChanged.is_visible
      }).map(shift => shift.id)
      this.form.ids_notallow = this.data.filter(shift => {
        return !this.form.ids_allow.includes(shift.id) ? shift.id : false
      })
        .map(shift => shift.id)
      this.$http
        .post('timekeeper.mss/shift-approval', this.form)
        .then(res => {
          this.$message.success('Enable Success')
          this.getShiftSchedules()
        }).catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    changeDayNames (id) {
      this.dayname.id = id
      this.dialogDayNames = true
    },
    updateDayName () {
      this.dialogDayNames = false
      this.dayname.allow_dayname = this.checkedDayNames.join(',')
      this.$http
        .post('timekeeper.mss/shift-dayname', this.dayname)
        .then(res => {
          this.$message.success('Days Changed')
          this.getShiftSchedules()
        })
    },
    getStatusType (type) {
      if (type) {
        return 'success'
      } else {
        return 'danger'
      }
    }
  }
}
</script>
